import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'
require('@/utils/Injection')

/*import tinymce from 'tinymce'
Vue.prototype.$tinymce = tinymce*/

import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/display.css';
import "element-ui/lib/theme-chalk/index.css";
import 'vant/lib/index.css';
import 'vant/lib/index.less';

import '@/assets/theme/index.css'
import "@/assets/global.css";
import "@/assets/iconfont/iconfont.css"

import Ellipsis from '@/components/common/Ellipsis'
import WDialog from '@/components/common/WDialog'
import Tip from '@/components/common/Tip'
import Avatar from '@/components/common/Avatar'
import VueAMap from "vue-amap";
import { List, PullRefresh, Checkbox, CheckboxGroup, DropdownMenu, DropdownItem, Calendar, Search, Sticky, NavBar, TreeSelect, Stepper, Button, Popup, Tab, Tabs, Icon, Dialog } from 'vant';

//VanNumberKeyboard
Vue.use(VueAMap)
Vue.use(ElementUI);
Vue.use(Ellipsis);
Vue.use(WDialog);
Vue.use(Tip);
Vue.use(Avatar);



//vant的组件
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Calendar);
Vue.use(Search);
//Vue.use(VanNumberKeyboard);
Vue.use(Sticky);
Vue.use(NavBar);
Vue.use(TreeSelect);
Vue.use(Stepper);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(Icon);



//Vue.use(Field);
Vue.config.productionTip = false

/*高德地图组件初始化*/
VueAMap.initAMapApiLoader({
  key: 'ebb9f6fb9beaec43fddcb22ab7406386',
  uiVersion: '1.0',
  plugin: [
    'AMap.Autocomplete',
    'AMap.ElasticMarker',
    'AMap.PlaceSearch',
    "AMap.MapType",
    "AMap.Geocoder",
    'AMap.Geolocation'
  ],
  v: '1.4.4'
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
